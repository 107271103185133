import * as React from "react"
import Datenschutz from "../components/Datenschutz"
import { GlobalStyle } from "../components/globalStyle"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Navbar from "../components/navbar2"

const datenschutz = () => {
  return (
    <>
      <Seo title="Datenschutz" />
      <Navbar />
      <GlobalStyle />
      <Datenschutz />
      <Footer />
    </>
  )
}

export default datenschutz
